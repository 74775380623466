<template>
  <div class="flex-col page">
        <!-- <div class="justify-between group_12">
          <span>日期</span>
          <div class="flex-row group_13">
            <span>时间</span>
            <span class="text_14">状态</span>
            <span class="text_15">下载</span>
          </div>
        </div> -->
        <div class="flex-col group_9">
          <!-- 信息列表 -->
          <!-- <div class="flex-col list-1">
            <div class="list-item-1 justify-between" :key="i" v-for="(item, i) in time">
              <div class="flex-row">
                <div class="left-group flex-row">
                  <span>{{item.date}}</span>
                  <span class="text_17">{{item.time}}</span>
                </div>
                <img class="image_21" :src="item.play" />
              </div>
              <img class="image_23" :src="item.dow" />
            </div>
          </div> -->
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Dnav: [{ navItem: '实时状态' }, { navItem: '电梯档案' }, { navItem: '部件管理' }, { navItem: '视频监控' }],
      list: [
        { title: '电梯标示编码', text: '23432432432423423423' },
        { title: '电梯出厂编号', text: '23432432432423423423' },
        { title: '设备代码', text: '23432432432423423423' },
        { title: '电梯品种', text: '拽引驱动乘客电梯' },
        { title: '电梯型号', text: 'GB -2323423423' },
        { title: '设备制造商', text: '三菱' },
        { title: '设备代理商', text: '三菱' },
        { title: '社会信用代码', text: '2323432423423423423' },
        { title: '设备出厂日期', text: '2021-12-31' },
        { title: '设备安装单位', text: '大连碧桂园物业管理有限公司' },
        { title: '设备安装日期', text: '2021-12-31' },
        { title: '层站数', text: '8层' },
        { title: '额定速度', text: '3m/s' },
        { title: '额定载重量', text: '158kg' },
        { title: '显示楼层', text: '3' }
      ],
      title: '数码大厦DT1',
      status: '在线',
      add: '沙河口区 中央大道109号阳光数码大厦A座',
      number: '注册代码：565448987988',
      addItem: '所在小区：星海人家',
      curId: 0
    }
  },
  methods: {
    tab(index) {
      this.curId = index
    }
  }
}
</script>

<style scoped>
@import '../../../assets/common.css';
.list-item {
  color: rgb(180, 180, 180);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
  padding-top: 0.38rem;
}
.active {
  color: rgb(0, 0, 0);
  font-size: 1.25rem;
  line-height: 1.19rem;
  white-space: nowrap;
  padding-top: 0;
}
.page {
  padding-top: 0.063rem;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.section_1 {
  padding: 0.94rem 0.81rem 0.88rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
  background-color: rgb(237, 237, 237);
}
.group_1 {
  overflow-y: auto;
  position: relative;
}
.image_1 {
  margin-right: 0.25rem;
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}
.section_2 {
  padding: 1.75rem 0 3.75rem 1.38rem;
  background: url(../../../assets/imgbg3.png) center top no-repeat;
  background-size: 100% 100%;
}
.line-tabs {
  overflow-x: hidden;
  border-radius: 1rem;
  background: #fff;
  margin-top: -2.4rem;
}
.image {
  margin-bottom: 0.13rem;
  width: 0.81rem;
  height: 0.81rem;
}
.text {
  margin-left: 0.75rem;
}
.group_2 {
  padding: 0 0.13rem;
}
.group_4 {
  margin-top: 1rem;
}
.list {
  padding: 1.88rem 0;
}
.text_1 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  line-height: 1.44rem;
  white-space: nowrap;
}
.group_3 {
  margin-right: 0.44rem;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.group_5 {
  flex: 1 1 auto;
}
.list-item:not(:first-of-type) {
  margin-left: 1.25rem;
}
.image_2 {
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background: #4cd964;
  border: 1px solid #fff;
  margin-top: 0.1rem;
}
.text_2 {
  margin-left: 0.31rem;
}
.text_3 {
  margin-left: 0.19rem;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.group_6 {
  margin-top: 1rem;
  padding: 0 0.063rem;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.81rem;
  white-space: nowrap;
}
.group_7 {
  margin-top: 0.81rem;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.81rem;
  white-space: nowrap;
}
.image_3 {
  width: 0.75rem;
  height: 0.94rem;
}
.text_4 {
  margin-left: 0.31rem;
  margin-top: 0.13rem;
}
.image_4 {
  width: 0.81rem;
  height: 0.88rem;
}
.text_5 {
  margin-left: 0.31rem;
}
.group_9 {
  width: 100%;
  box-sizing: border-box;
  padding: 0 1rem;
}
.group_12 {
  padding: 0 0.13rem 1.13rem;
  color: rgb(0, 0, 0);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
  border-bottom: solid 0.063rem rgb(243, 249, 244);
  box-sizing: border-box;
  padding: 0 1rem;
}
.group_13 {
  margin-right: 0.38rem;
}
.text_14 {
  margin-left: 3.69rem;
}
.text_15 {
  margin-left: 3.81rem;
}
.list-item-1 {
  padding: 1rem 0;
  color: rgb(179, 179, 178);
  font-size: 0.75rem;
  line-height: 0.63rem;
  white-space: nowrap;
  border-bottom: solid 0.063rem rgb(243, 249, 244);
}
.image_23 {
  margin-right: 0.6rem;
  width: 1.13rem;
  height: 1.13rem;
}
.left-group {
  margin: 0.25rem 0 0.19rem;
}
.image_21 {
  margin-left: 3.6rem;
  width: 1.13rem;
  height: 1.13rem;
}
.text_17 {
  margin-left: 3.81rem;
}
.list-1 {
  padding-top: 0.063rem;
}
</style>

